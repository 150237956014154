._media{
  padding: 50px 0;
  .media-wrapper{
    padding: 10px;
    .media-item{
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      margin: 5px 0;
    }
  }
}