._roadmap {
  color: #fff;
  padding: 50px 0;
  background-image: url('../../../assets/ill/spt-4.png');
  background-position: center;
  background-size: cover;
  .wrapper {
    position: relative;
    .line {
      position: relative;
      .line-h{
        height: 1px;
        background-color: var(--white);
        width: 100%;
      }
    }
    .yearItem {
      background-color: #fff;
      // max-width: max-content;
      margin-bottom: 10px;
      color: var(--dark);
      padding: 5px 10px;
      font-weight: 700;
      border-radius: 5px;
      font-size: 22px;
      text-align: center;
    }
    .cardLineTime{
      height: 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      justify-content: flex-start;
      .line-zero{
        background-color: #FFF;
        height: 2px;
        width: 100%;
        margin-top: 5px;
        display: flex;
        justify-content: center;
        .box{
          width: 10px;
          height: 10px;
          background-color: #FFF;
        }
      }
    }
    .cardCalendar{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;
      position: relative;
      .line-time{
        height: 2px;
        width: 100%;
        background-color: #FFF;
        position: absolute;
        top: 0;
      }
      .dot-time{
        height: 10px;
        width: 10px;
        background-color: #FFF;
        position: absolute;
        top: 0;
      }
    }
    .q {
      margin-bottom: 60px;
      background-color: var(--dark);
      padding: 10px;
      border-radius: 10px;
      @media (max-width: 768px) {
        border-radius: 5px;
        padding: 10px;
        margin-bottom: 10px;
      }
      .title {
        background-color: var(--red);
        padding: 5px;
        border-radius: 5px;
        font-size: 26px;
        text-align: center;
        color: var(--dark);
        font-weight: 700;
      }
      ul {
        margin: 20px;
        padding: 0;
        li {
          margin: 0;
          font-weight: 700;
        }
      }
    }
  }
}
