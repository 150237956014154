._tokenomics{
  position: relative;
  padding-bottom: 50px;
  .ring{
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: #ffe7a6;
    box-shadow: 0 0 30px #FFF;
    position: sticky;
    margin-top: 10px;
    top: 10%;
    left: 50px;
  }
  .chartWrapper{
    background-color: var(--dark);
    display: flex;
    min-height: 50vh;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    .apexcharts-legend-series{
      .apexcharts-legend-text{
        color: #FFF !important;
      }
    }
  }
  .token-detail{
    .title{
      display: flex;
      margin-bottom: 10px;
      color: var(--white);
      font-weight: 700;
      @media (max-width:992px) {
        text-align: center;
        display: block;
        margin-top: 30px;
      }
    }
    .item{
      padding: 8px 0;
      display: flex;
      flex-direction: column;
      @media (max-width:768px) {
        justify-content: center;
      }
      background-color: var(--dark);
      .name{
        color: var(--red);
        font-weight: 600;
        font-size: 16px;
      }
      .value{
        font-weight: 300;
        color: var(--white);
        font-size: 14px;
      }
      @media (max-width:992px) {
        .name, .value{
          text-align: center;
        }
      }
    }
  }
}