._partner{
  padding: 20px 0;
  .wrapper{
    .coin-item{
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0;
    }
  }
}