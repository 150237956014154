._presale{
  padding: 50px 0;
  position: relative;
  overflow: hidden;
  .pre-circle{
    position: absolute;
    height: 320px;
    width: 320px;
    // background-color: rgba(255, 0, 0, 0.452);
    border: 2px dashed var(--red);
    left: 40%;
    border-radius: 50%;
    z-index: -1;
  }
  .cardPresale{
    background-color: var(--dark);
    box-shadow: 0 0 5px rgba(255, 255, 255, .2);
    border-radius: 10px;
    padding: 20px;
    .item{
      display: flex;
      flex-direction: column;
      padding: 10px 0;
      .title{
        font-size: 22px;
        color: var(--white);
        font-weight: 600;
        margin-bottom: 5px;
      }
      .val{
        color: var(--white);
      }
    }
  }
}