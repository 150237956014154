._hero {
  padding: 50px 0;
  background-image: url('../../../assets/ill/spt-4.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 50px;
  padding: 50px 0;
  overflow-x: hidden;
  .floating-logo {
    width: 50px;
    position: absolute;
    right: 50px;
    top: 30px;
    .logo-std {
      width: 100%;
    }
  }
  .ill-hero {
    background-image: url("../../../assets/ill/spt-7.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
  .video-wrapper {
    height: 80vh;
    width: 420px;
    background-color: var(--dark);
    border-radius: 20px;
    padding: 20px;
    max-width: max-content;
    box-shadow: 0 0 4px rgba(255, 255, 255, 0.3);
    .videoHero {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: right;
    }
  }
  .wrapper {
    color: var(--white);
    z-index: 1;
    // height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title-wrapper {
      z-index: 2;
      .title1 {
        display: flex;
        margin-bottom: 30px;
        .logo-name {
          width: 320px;
          // margin: 0 10px;
          margin-top: 14px;
          object-fit: contain;
        }
        h1 {
          font-weight: 800;
          font-size: 46px;
          text-transform: uppercase;
        }
      }
      .title2 {
        display: flex;
        margin-bottom: 30px;
        b {
          color: #fcdd80;
          // text-shadow:0px 1px 1px #000,1px 1px 1px #000,
          //   -1px 1px 1px #000, 1px -2px 1px #fcdd80, -2px 2px 0px #fcdd80,
          //   2px 3px 1px #eeeeee, 4px 3px 1px #FFF, 3px 4px 1px #eeeeee,
          //   5px 4px 1px #FFF, 4px 5px 1px #eeeeee, 6px 5px 1px #FFF,
        }
        h1 {
          font-weight: 800;
          font-size: 48px;
          max-width: 100%;
          text-transform: uppercase;
        }
      }
    }
  }
  .action-wrapper {
    display: flex;
    // width: max-content;
    z-index: 2;
    .btn-hero {
      background-color: transparent;
      height: 50px;
      padding: 0 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 600;
      border: 2px solid var(--red);
      border-radius: 6px;
      color: var(--red);
      transition: all ease-in-out 0.2s;
      &:hover {
        color: var(--white);
        background-color: var(--red);
      }
    }
  }
}

@media (max-width: 992px) {
  ._hero {
    position: relative;
    .floating-logo {
      width: 50px;
      position: absolute;
      right: 50px;
      top: 80px;
      .logo-std {
        width: 100%;
      }
    }
    .video-wrapper {
      height: 80vh;
      width: 100%;
      border-radius: 20px;
      padding: 20px;
      max-width: max-content;
      .videoHero {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .wrapper {
      color: var(--white);
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title-wrapper {
        z-index: 2;
        padding: 30px 0;
        .title1 {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 20px;
          .logo-name {
            width: 220px;
            // margin: 0 10px;
            margin-top: 14px;
            object-fit: contain;
          }
          h1 {
            font-weight: 800;
            font-size: 30px;
            text-align: center;
          }
        }
        .title2 {
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          h1 {
            font-weight: 800;
            font-size: 28px;
            text-align: center;
            max-width: 100%;
            text-align: center;
          }
        }
      }
    }
    .action-wrapper {
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      .btn-hero {
        height: 40px;
        padding: 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
        border: 2px solid var(--red);
        border-radius: 6px;
        color: var(--red);
        transition: all ease-in-out 0.2s;
        &:hover {
          color: var(--white);
          background-color: var(--red);
        }
      }
    }
  }
}
