._times{
  padding: 50px 0;
  .team-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .image-wrapper{
      height: 250px;
      width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      border: 5px solid var(--red);
      border-radius: 50%;
      .img{
        object-fit: cover;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .info-wrapper{
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .name{
        color: var(--white);
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 20px;
      }
      .job-title{
        color: #AEAEAE;
        font-size: 14px;
      }
    }
  }
}