._project {
  .mb-10 {
    margin: 100px 0;
  }
  .product-wrapper {
    margin-top: 50px;
    position: relative;
    .ill-image-product {
      height: 200px;
      width: 200px;
      background-image: linear-gradient(rgba(255, 255, 255, 0.1),rgba(255, 153, 0, 0.2));
      position: absolute;
      box-shadow: 0 0 10px rgb(32, 2, 2);
      z-index: -1;
      top: -100px;
      left: -20px;
      border-radius: 50%;
    }
    .product-img {
      width: 100%;
      height: 80%;
      border-radius: 10px;
    }
  }
  .product-info-wrapper {
    margin-top: 50px;
    padding: 20px;
    position: relative;
    background-image: url('../../../assets/ill/spt-7.png');
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    .ill-product {
      position: absolute;
      background: linear-gradient(to left, rgba(223, 195, 38, 0.2), #000);
      z-index: -1;
      right: -130px;
      left: 0;
      bottom: 0;
      top: 0;
      border-radius: 10px 0 0 10px;
      &.left {
        left: -130px;
        background: linear-gradient(to right, rgba(255, 187, 1, 0.1), #000);
      }
    }
    .title-1 {
      .title {
        color: var(--white);
        font-weight: 700;
        font-size: 56px;
        z-index: 1;
        text-shadow: 1px 1px 1px #000;
      }
      .desc {
        color: var(--white);
        font-size: 18px;
        font-weight: 700;
        margin-left: 30px;
        z-index: 2;
        text-shadow: 0 0 3px #000;
      }
    }
  }
}

@media (max-width: 768px) {
  ._project {
    .product-info-wrapper {
      margin-top: 50px;
      padding: 20px;
      position: relative;
      .ill-product {
        position: absolute;
        background: linear-gradient(to left, var(--red), #000);
        z-index: -1;
        right: 0;
        left: 0;
        bottom: 0;
        top: 0;
        border-radius: 0 10px 10px 0;
        &.left {
          left: 0;
          background: linear-gradient(to right, var(--red), #000);
          border-radius: 10px 0 0 10px;
        }
      }
      .title-1 {
        .title {
          color: var(--white);
          font-weight: 700;
          font-size: 26px;
          z-index: 1;
        }
        .desc {
          color: var(--white);
          font-size: 18px;
          font-weight: 400;
          margin-left: 30px;
          z-index: 2;
        }
      }
    }
  }
}
