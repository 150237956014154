._footer{
  background-color: var(--dark);
  .backedby{
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title-backed{
      font-weight: 700;
      color: var(--white);
      font-size: 36px;
      margin-bottom: 30px;
    }
    .img-backed{
      width: 220px;
    }
  }
  .motto{
    margin: 50px 0;
    .top{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .image-wrapper{
        width: 300px;
        height: 300px;
        .img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
      }
      .hashtag{
        font-weight: 800;
        font-size: 20px;
        color: var(--white);
        margin: 10px 0;
      }
    }
    .bottom{
      margin-bottom: 50px;
      p{
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        color: var(--white);
      }
    }
  }
  .links{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 20px;
    .link{
      font-weight: 500;
      color: var(--white);
      margin: 0 10px;
    }
  }
  .social{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    .item{
      margin: 0 10px;
      color: var(--white);
      font-size: 22px;
    }
  }
}