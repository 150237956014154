.title-page-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 46px;
    text-align: center;
    color: var(--white);
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 30px;
    position: relative;
    z-index: 2;
    &::after {
      content: "";
      width: 50px;
      height: 8px;
      border-radius: 6px;
      background-color: var(--red);
      display: block;
      position: absolute;
      bottom: 0;
      z-index: 1;
    }
  }
}
._about {
  padding: 30px 0;
  overflow: hidden;
  .wrapper {
    position: relative;
    .ill-about {
      height: 400px;
      width: 300px;
      position: absolute;
      border: 2px dashed var(--red);
      border-radius: 50%;
      left: -300px;
      top: -10px;
      z-index: 2;
    }
    .ill-about-2 {
      height: 300px;
      width: 300px;
      position: absolute;
      border: 2px dashed var(--red);
      border-radius: 50%;
      left: -350px;
      top: 100px;
      z-index: 2;
    }
    .ill-about-3 {
      z-index: -1;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        opacity: .5;
      }
    }
    .about-container {
      .item {
        margin-bottom: 30px;
        .title {
          z-index: 2;
          font-size: 38px;
          font-weight: 800;
          color: var(--white);
          text-transform: uppercase;
        }
        .desc {
          z-index: 2;
          font-size: 18px;
          font-weight: 700;
          color: var(--white);
        }
      }
    }
    .image-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .img {
        width: 70%;
      }
    }
  }
}
